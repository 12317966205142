<template>
  <div class="cadastro-usuario">
    <v-container fluid>
      <h1 class="titulo">Prêmios</h1>

      <v-row>
        <v-col>
          <v-card>
            <v-card-text class="modal-cadastro-form pa-3 pb-0">
              <v-row>
                <!-- <v-col cols="12" :md="3" class="pa-3 pt-1 mt-0 pb-0" sm="12">
                  <v-menu :close-on-content-click="true" :nudge-right="35" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="dtInicialString" label="Data"  prepend-icon="mdi-calendar" v-on="on" v-bind="attrs" readonly/>
                    </template>
                    <v-date-picker v-model="dtInicial"  @input="formatarData(dtInicial)"/>
                  </v-menu>
                </v-col> -->

                <v-col cols="12" dense md="3" class="pt-3 pa-3 pb-0">
                  <v-text-field
                    :class="'bug-input'"
                    :label="$gettext('Código do Bilhete')"
                    v-model="codBilhete"
                    :error-messages="codBilheteErrors"
                    dense
                    @input="$v.codBilhete.$touch()"
                    @blur="$v.codBilhete.$touch()"
                  />
                </v-col>

                <v-col cols="12" dense md="3" class="pt-3 pa-3 pb-0">
                  <v-btn
                    type="submit"
                    :color="variables.colorPrimary"
                    @click.prevent="openModal"
                    class="mr-2"
                    tile
                    outlined
                  >
                    Ler QrCode
                  </v-btn>

                  <v-btn
                    type="submit"
                    :color="variables.colorPrimary"
                    @click.prevent="submit"
                    class="br-btn mr-2"
                    :loading="loading"
                  >
                    Buscar
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <!-- <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit" v-if="!errorNotCamera && !destroyed">
                    <p v-if="errorPermissaoCamera" class="msg-error-qrcode">{{ errorPermissaoCamera }}</p>
                    <div v-if="loading" style="background: #FFF; width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;">
                    <img width="80" src="~@/assets/Loading_2.gif" />
                  </div> 
                </qrcode-stream> -->
              </v-row>
            </v-card-text>

            <v-card-actions class="pt-0"> </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <div class="box-table box-table-ganhadores">
        <v-skeleton-loader
          :loading="loadingTable"
          :transition="'fade-transition'"
          height="500"
          type="table"
        >
          <v-data-table
            v-model="itensSelect"
            :headers="headers"
            :items="listaItens"
            show-expand
            :expanded.sync="expanded"
            hide-default-footer
            :loading="loadingPag"
            :items-per-page.sync="itemsPerPage"
            @item-expanded="itemExpandRequest"
          >
            <template v-slot:item.winners.games.gameNumber="{ item }">
              {{
                item.winners
                  ? item.winners.games.gameNumber
                  : formatGeral(item.params, "gameId")
              }}
            </template>
            <template v-slot:item.winners.sweepstake.cod="{ item }">
              {{
                item.winners
                  ? item.winners.sweepstake.cod
                  : formatGeral(item.params, "labelLottery")
              }}
            </template>

            <template v-slot:item.winners.totais.guessValue="{ item }">
              {{
                item.winners
                  ? item.winners.totais.guessValue
                  : vlGamesValue(item.params) | currency
              }}
            </template>

            <template v-slot:item.value="{ item }">
              {{ item.value | currency }}
            </template>

            <template v-slot:item.totais.prizeValue="{ item }">
              {{ item.totais.prizeValue | currency }}
            </template>

            <template v-slot:item.winners.sweepstake.dtDraw="{ item }">
              {{
                item.winners
                  ? item.winners.sweepstake.dtDraw
                  : item.created | datas
              }}
            </template>

            <template v-slot:item.winners.games.created="{ item }">
              {{
                item.winners ? item.winners.games.created : item.created | datas
              }}
            </template>

            <template v-slot:item.actions="{ item }">
              <!-- Premio já Baixado -->
              <v-chip
                v-if="item.status === 'B'"
                :color="variables.colorSecondary"
                dark
              >
                Baixado
              </v-chip>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <div class="box-table-palpites">
                  <v-skeleton-loader
                    :loading="loadingSubTable"
                    :transition="'fade-transition'"
                    type="table"
                    height="500"
                  >
                    <div v-if="item.new">
                      <div :key="`label-${k}`" class="mt-2"></div>
                      <v-data-table
                        class="mt-2"
                        :headers="headersPrizeWeb"
                        :items="item.new"
                        hide-default-footer
                        :key="`web-${k}`"
                        dense
                        :items-per-page="item.new.length"
                      >
                        <template v-slot:item.guesses="{ item }">
                          {{ item.guesses }}
                        </template>

                        <template v-slot:item.guessValue="{ item }">
                          {{ item.guessValue | currency }}
                        </template>

                        <template v-slot:item.prizeValue="{ item }">
                          {{ item.prizeValue | currency }}
                        </template>
                      </v-data-table>
                    </div>

                    <!-- se for premio sem agrupamento de loterias -->
                    <v-data-table
                      v-else
                      :headers="headersSubTable"
                      :items="
                        item.winners
                          ? item.winners.games.guessWinners
                          : item.new
                      "
                      hide-default-footer
                      dense
                      item-key="bb"
                      :key="'asdsa'"
                      :items-per-page="
                        item.winners
                          ? item.winners.games.guessWinners.length
                          : item.new
                          ? item.new.length
                          : 0
                      "
                    >
                      <template v-slot:item.prizeValue="{ item }">
                        {{ item.prizeValue | currency }}
                      </template>

                      <template v-slot:item.guessValue="{ item }">
                        {{ item.guessValue | currency }}
                      </template>
                    </v-data-table>
                  </v-skeleton-loader>
                </div>
              </td>
            </template>

            <!-- <template slot="body.append">
              <tr style="background: #4CAF50; color:white;">
                  <th colspan="3" style="font-weight: 400 !important">Nº Bilhetes: {{ totalGeral.bilhetes ? totalGeral.bilhetes : '0' }} </th>
                  <th style="font-weight: 400 !important">Valor jogos: {{ totalGeral.jogos? totalGeral.jogos : '0,00' | currency }} </th>
                  <th style="font-weight: 400 !important">Prêmios Pagos: {{ totalGeral.pagos ? totalGeral.pagos : '0,00' | currency }} </th>
                  <th style="font-weight: 400 !important">Prêmios Pendentes: {{ totalGeral.pendentes? totalGeral.pendentes : '0,00' | currency }} </th>
                  <th style="font-weight: 400 !important">Total Geral: {{ totalGeral.totais === 0 ? '0,00' : totalGeral.totais | currency }} </th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
              </tr>
            </template> -->

            <template v-slot:footer>
              <div class="wrapper-footer">
                <div class="qtd-paginacao">
                  {{ pageStart }}
                  -
                  {{ pageStop }}
                  de
                  {{ totalItens }}
                </div>
                <v-pagination
                  v-model="page"
                  :length="numberOfPages"
                  circle
                  :disabled="disabled"
                  :total-visible="7"
                  class="box-table-paginacao"
                  v-if="numberOfPages > 1"
                ></v-pagination>
              </div>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
      <modalQrCode
        :activeModal="activeModal"
        @closeModal="closeModal"
        @getPremioQrCode="getPremioQrCode"
      />
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Events from "@/core/service/events";
import { validationMixin } from "vuelidate";
import { requiredIf } from "vuelidate/lib/validators";
import moment from "moment";
// import { currency } from '@/core/service/utils'
import { map, reduce, findIndex } from "lodash";
// import { QrcodeStream } from 'vue-qrcode-reader'

/**
 * MIXIN
 */
import listagemTables from "@/views/mixins/listagemTables";

const namespaceStore = "premios";

export default {
  name: "ConsutlasPremios",
  components: {
    // BrToolBar: () => import('./components/toolbar'),
    // rangeDate: () => import('../../components/rangeDate'),
    modalQrCode: () => import("./components/modalQrCode"),
    // QrcodeStream
  },
  mixins: [listagemTables, validationMixin],
  data: () => ({
    premioAlto: false,
    listWinnersGambis: [],
    entity: {},
    expanded: [],
    headers: [
      { align: "start", class: "table-header", text: "id", value: "id" },
      {
        align: "start",
        class: "table-header",
        text: "N° Jogo",
        value: "winners.games.gameNumber",
      },
      {
        align: "start",
        class: "table-header",
        text: "Loteria",
        value: "winners.sweepstake.cod",
      },
      {
        align: "start",
        class: "table-header",
        text: "V.Jogo(R$)",
        value: "winners.totais.guessValue",
      },
      {
        align: "start",
        class: "table-header",
        text: "V.Prêmio(R$)",
        value: "value",
      },
      {
        align: "start",
        class: "table-header",
        text: "Código Revenda",
        value: "entity.cod",
      },
      {
        align: "start",
        class: "table-header",
        text: "Data Sorteio",
        value: "winners.sweepstake.dtDraw",
      },
      {
        align: "start",
        class: "table-header",
        text: "Transmissão",
        value: "winners.games.created",
      },
      { align: "end", class: "table-header", text: "", value: "actions" },
    ],

    headersSubTable: [
      {
        align: "start",
        class: "table-header",
        text: "Modalidade",
        value: "modality.name",
      },
      {
        align: "start",
        class: "table-header",
        text: "Palpite",
        value: "guess.guess",
      },
      {
        align: "start",
        class: "table-header",
        text: "Prêmio",
        value: "prizeRange",
      },
      {
        align: "start",
        class: "table-header",
        text: "Valor Jogo(R$)",
        value: "guessValue",
      },
      {
        align: "start",
        class: "table-header",
        text: "Valor Prêmio (R$)",
        value: "prizeValue",
      },
    ],
    headersPrizeWeb: [
      {
        align: "start",
        class: "table-header",
        text: "Loteria",
        value: "lottery",
      },
      {
        align: "start",
        class: "table-header",
        text: "Modalidade",
        value: "modality",
      },
      {
        align: "start",
        class: "table-header",
        text: "Palpite",
        value: "guesses",
      },
      {
        align: "start",
        class: "table-header",
        text: "Prêmio",
        value: "prizeRange",
      },
      {
        align: "start",
        class: "table-header",
        text: "Valor Jogo(R$)",
        value: "guessValue",
      },
      {
        align: "start",
        class: "table-header",
        text: "Valor Prêmio (R$)",
        value: "prizeValue",
      },
    ],

    loading: false,
    loadingTable: false,
    loadingSubTable: false,
    dtFinal: "",
    // dtInicial: new Date().toISOString().substr(0, 10),
    dtInicialString: moment().format("DD/MM/YYYY"),
    inativos: null,
    hasMonthOrDate: null,
    loterias: [],
    diff: 1,
    loteria: "",
    itensSelect: [],
    codBilhete: "",
    baixados: false,
    page: 1,
    activeModal: false,
  }),

  watch: {
    hasMonthOrDate(val) {
      this.setHasMonthOrDate(val);
    },

    loteria() {
      this.page = 1;
      this.clearPage();
    },
  },

  mounted() {
    const vm = this;
    this.$on("item-expanded", (obj) => {
      console.log("veio aqui", obj);
    });

    Events.$on("update::view::baixa", () => {
      vm.submit();
    });

    this.getSweepstakesRecurrent({ order: { id: "desc" } }).then((result) => {
      this.loterias = result.data.resultMap.map((item) => ({
        text: `${item.id} - ${item.cod}`,
        value: item.id,
      }));

      this.loterias.unshift({
        text: "Todos",
        value: "",
      });
    });
    
    if (this.$route.query.codBilhete) {
      this.codBilhete = this.$route.query.codBilhete
      this.submit()
    }
  },

  computed: {
    ...mapGetters(namespaceStore, ["listaItens", "totalItens"]),
    ...mapGetters("auth", ["user"]),

    isAdmin() {
      return this.user.userEntityPaths.length > 0 ? false : true;
    },

    codBilheteErrors() {
      const errors = [];
      if (!this.$v.codBilhete.$dirty) return errors;
      !this.$v.codBilhete.required &&
        errors.push("Código do bilhete é obrigatório");
      return errors;
    },

    totalGeral() {
      let jogos = this.listaItens[0];
      if (!jogos || jogos === undefined) return { pagos: 0, pendentes: 0 };

      let premios = {
        pagos: parseFloat(jogos.allValues[0].downloaded).toFixed(2),
        pendentes: parseFloat(jogos.allValues[0].pending).toFixed(2),
        jogos: parseFloat(jogos.allValues[0].games),
        bilhetes: parseFloat(jogos.allValues[0].tickets).toFixed(2),
      };

      premios.totais = +premios.pagos + +premios.pendentes;
      return premios;
    },
  },

  methods: {
    ...mapActions(namespaceStore, [
      "getItens",
      "setSubTable",
      "getPremioAltos",
      "clearItens",
    ]),
    ...mapActions("resgate", {
      baixarPremios: "editarItem",
      prescreverPremio: "prescreverPremio",
    }),

    ...mapActions("sweepstakesRecurrent", {
      getSweepstakesRecurrent: "getItens",
    }),

    init() {},
    openModalBaixar(item) {
      Events.$emit("ganhadores::modal::baixar", item);
    },

    openModal() {
      this.activeModal = true;
    },

    closeModal() {
      this.activeModal = false;
    },

    getPremioQrCode(codBilhete) {
      this.codBilhete = codBilhete;
      this.submit();
    },

    itemExpandRequest(v) {
      this.expanded = v.item === this.expanded[0] ? [] : [v.item];

      if (!v.value) return false;
      this.listWinnersGambis = [];
      let index = findIndex(this.listaItens, (obj) => obj.id === v.item.id);
      if (v.item.pendingOperationTypeId === "SRP") {
        this.loadingSubTable = true;
        let format = map(v.item.params, (val) => {
          return {
            modality: { name: val },
            guess: { guess: val },
            prizeRange: val,
            guessValue: val.vlGame,
            prizeValue: val,
            games: { gameNumber: 1, guessWinners: [{}] },
            totais: {
              guessValue: 1,
            },
          };
        });

        this.setSubTable({
          format,
          id: v.item.id,
          key: index,
          lista: v.item.params.list,
        }).then(() => {
          this.loadingSubTable = false;
        });
      } else {
        return;
      }
    },
    limpar() {
      this.entity = {};
      this.premioAlto = false;
      this.baixados = false;
    },
    paginacao(pag) {
      this.loadingPag = true;
      this.disabled = true;
      this.setPage(pag);
      this.page = pag;
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      this.submit();
    },

    formatarData(date) {
      this.dtInicialString = moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
      return;
    },

    submit() {
      if (this.$v.codBilhete.$invalid) {
        this.$v.codBilhete.$touch();
        return false;
      }

      this.loading = true;
      this.loadingPag = true;
      this.disabled = true;
      this.expanded = [];

      // eslint-disable-next-line
      let { codBilhete } = this
      this.getItens({
        codBilhete
      })
      .then(() => {
        this.loading = false
        this.loadingPag = false
        this.disabled = false
        this.closeModal()
      })
      .catch((err) => {
        this.loading = false
        this.loadingPag = false
        this.disabled = false
        this.closeModal()
        console.log(err)
      });
    },
    requestPremiosAltos() {
      this.premioAlto = !this.premioAlto;
      this.submit();
    },
    requestBaixados() {
      this.baixados = !this.baixados;
      this.submit();
    },

    handlerBaixarPremios(item) {
      this.itensSelect = [];
      this.itensSelect.push(item);
      this.baixarSelecionados();
    },
    vlGamesValue(obj) {
      if (!obj) return 0;
      delete obj.token;
      return reduce(obj, (a, b) => parseFloat(a) + parseFloat(b.vlGame), 0);
    },
    formatGeral(obj, key) {
      if (!obj) return "-";
      delete obj.token;
      return map(obj.list, (v) => v[key]).join(" - ");
    },
  },

  validations() {
    return {
      codBilhete: {
        required: requiredIf(function () {
          return !this.isAdmin;
        }),
      },
    };
  },
};
</script>

<style lang="scss">
@import "~@/assets/styles/components/table";
.bug-input {
  position: relative;
  top: -4px;
}
.v-data-table thead tr th,
.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
  color: $colorPrimary !important;
}
.v-btn--contained.button-premio {
  font-size: 14px;
  border-radius: 0 !important;
  color: $colorPrimary !important;
  border: 1px solid $colorPrimary !important;
  box-shadow: none !important;
  font-weight: normal !important;
  &-alto {
    border-radius: 0 !important;
    font-size: 14px;
    font-weight: normal !important;
    box-shadow: transparent !important;
    color: white !important;
  }
}
.box-table {
  // border-top: 5px solid $colorPrimary;
  // border-top-left-radius: 0 !important;
  // border-top-right-radius: 0 !important;

  .v-data-table-header-mobile {
    display: none;
  }

  &.box-table-ganhadores {
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
      // padding: 15px 0;
    }

    .v-data-table tbody tr.v-data-table__expanded__content {
      box-shadow: none;
    }
  }

  .box-table-palpites {
    .v-data-table {
      margin: 20px 0;
    }
  }
}

.fab-cadastrar-usuario {
  position: fixed;
  z-index: 1;
  bottom: 25px;
  right: 5px;

  @media (min-width: 599px) {
    display: none;
  }
}
.position-buttons {
  @media (max-width: 600px) {
    text-align: center;
  }
}
</style>>
